<template>
  <div class="wrapper">
    <img class="wrapper__img" src="images/user.png" />
    <div class="wrapper__input">
      <input
        name="username"
        class="wrapper__input__content"
        placeholder="用户名"
        v-model="loginForm.username"
      />
    </div>
    <div class="wrapper__input">
      <input
        name="password"
        type="password"
        class="wrapper__input__content"
        v-model="loginForm.password"
        placeholder="请输入密码"
        autocomplete="new-password"
        @keyup.enter="handleLogin"
      />
    </div>
    <div class="wrapper__login-button" @click="handleLogin">登录</div>
    <div class="wrapper__login-link" @click="handleRegisterClick">立即注册</div>
    <Toast v-if="show" :message="toastMessage" />
  </div>
</template>

<script setup>
// @ is an alias to /src
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Notify } from '@nutui/nutui'

const loginForm = ref({
  username: '',
  password: ''
})

// const error = reactive({
//   msg: '',
//   iserror: false
// })

const store = useStore()
const router = useRouter()

const handleLogin = () => {
  // 1. 进行表单校验
  // 2. 触发登录

  store
    .dispatch('user/userLogin', loginForm.value)
    .then(() => {
      Notify.success('登录成功', {
        duration: 1000,
        onClose() {
          router.push('/')
        }
      })
    })
    .catch((error) => {
      // showToast('请求失败')
      // error.iserror = true
      // let separatemessage = String(err).split(':')
      // error.msg = separatemessage[2]
      Notify.danger(error, {
        duration: 3000
      })
    })
}

// 处理注册跳转
const handleRegisterClick = () => {
  router.push({ name: 'Register' })
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
.wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  &__img {
    display: block;
    margin: 0 auto 0.4rem auto;
    width: 0.66rem;
    height: 0.66rem;
    border-radius: 50%;
  }
  &__input {
    height: 0.48rem;
    margin: 0 0.4rem 0.16rem 0.4rem;
    padding: 0 0.16rem;
    background: #f9f9f9;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border-radius: 6px;
    &__content {
      line-height: 0.48rem;
      border: none;
      outline: none;
      width: 100%;
      background: none;
      font-size: 0.16rem;
      color: $content-notice-fontcolor;
      &::placeholder {
        color: $content-notice-fontcolor;
      }
    }
    &__show-pwd {
      position: absolute;
      font-size: 0.16rem;
    }
  }
  &__login-button {
    margin: 0.32rem 0.4rem 0.16rem 0.4rem;
    line-height: 0.48rem;
    background: #0091ff;
    box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 145, 255, 0.32);
    border-radius: 0.04rem;
    border-radius: 0.04rem;
    color: $bgColor;
    font-size: 0.16rem;
    text-align: center;
  }
  &__login-link {
    text-align: center;
    font-size: 0.14rem;
    color: $content-notice-fontcolor;
  }
}
</style>
